import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/templates/mdx-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "2020-holiday-schedule"
    }}>{`2020 Holiday Schedule`}</h2>
    <p>{`We are closed for the following holidays:`}</p>
    <ul>
      <li parentName="ul">{`May 25th: Memorial Day`}</li>
      <li parentName="ul">{`July 4th: Independence Day`}</li>
      <li parentName="ul">{`Sep 7th: Labor Day`}</li>
      <li parentName="ul">{`Nov 26-27th: Thanksgiving`}</li>
      <li parentName="ul">{`Dec 24-26: Winter Holiday`}</li>
      <li parentName="ul">{`Dec 31 - Jan 1 2021: New Year`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      